import { Partition } from '@snapchat/graphene';

import type { SiteConfiguration } from '../../configTypes';
import { LoggingVendor } from '../../helpers/logging/eventListenerTypes';

export const config: SiteConfiguration = {
  domainName: 'diversity.snap.com',

  redirects: {
    hostRedirects: {},
  },

  trackingSettings: {
    cookieDomain: 'snap.com',
    eventListeners: [
      { vendor: LoggingVendor.BLIZZARD },
      { vendor: LoggingVendor.CONSOLE },
      { vendor: LoggingVendor.GOOGLE_CLOUD_LOGGING },
      { vendor: LoggingVendor.GOOGLE_CLOUD_MONITORING },
      { vendor: LoggingVendor.GOOGLE_CLOUD_ERROR_REPORTING },
      { vendor: LoggingVendor.MWP_HERMES },
      { vendor: LoggingVendor.GRAPHENE, partitionName: Partition.DIVERSITY },
      {
        vendor: LoggingVendor.GOOGLE_TAG_MANAGER,
        googleAnalyticsId: 'G-QSNJ69K7MK',
        googleTagManagerId: 'GTM-PB72WQ3',
      },
      {
        vendor: LoggingVendor.SENTRY,
        projectName: 'diversity-snap-com',
        dsn: 'https://5fd54fecdff1435e9ea4c3d0cbfd5969@sentry.sc-prod.net/176',
      },
    ],
  },
  globalNavProps: {
    defaultGroupKey: 'safety',
    siteName: 'Diversity @ Snap',
  },
};
